import React from 'react'
import {Deutsch, English, Type, LinkABOUT, Page, Back_To_All_Projects} from '../../components/linksetc.js'
import Layout from '../../components/layout'

import img1 from "../../images/velocomic/1.jpg"
import img2 from "../../images/velocomic/2.jpg"
import img3 from "../../images/velocomic/3.jpg"
import img4 from "../../images/velocomic/4.jpg"


import img5 from "../../images/velocomic/5.jpg"
import img6 from "../../images/velocomic/6.jpg"
import img7 from "../../images/velocomic/7.jpg"
import img8 from "../../images/velocomic/8.jpg"

// for the scrolldown
var Scroll  = require('react-scroll');
var ScrollLink       = Scroll.Link;
var Element    = Scroll.Element;




export default ({ location }) => (
  <Layout>


      <Page location={location}>




      <div className="project_description">

        <English Language={location.search}>
              <Type>
                    Every year, Fumetto holds an international comic contest. In 2019, the given topic was the bycicle.
                    <p/>
                    So we came up with a story that illustrates the untapped potential of the bycicle. <a href="http://www.iamsafu.ch" target="_blank">Sarah Furrer</a> drew the comic and I wrote the text. The Swiss secret service is probably observing us now.
                    <p/>
                    <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>
              </Type>
        </English>

         <Deutsch Language={location.search}>
              <Type>

              Fumetto veranlasst jedes Jahr einen internationalen Comicwettbewerb. 2019 war "Velo" das vorgegebene Thema.
                    <p/>
                     Wir erfanden eine Geschichte, die das ungenutzte Potential des Velos illustriert. <a href="http://www.iamsafu.ch" target="_blank">Sarah Furrer</a> zeichnete den Comic und ich schrieb den Text. Der Schweizer Nachrichtendienst überwacht uns jetzt wahrscheinlich.
                    <p/>
                    <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>
              </Type>
        </Deutsch>

    </div>

    <Element name="test1"></Element>


      <img src={img1}/>
      <img src={img2}/>
      <img src={img3}/>
      <img src={img4}/>


      <img src={img5}/>
      <img src={img6}/>
      <img src={img7}/>
      <img src={img8}/>








      <Back_To_All_Projects location={location} />



      </Page>
    </Layout>

)
